import React from 'react';
import './Home.scss';

import MarkdownContent from 'components/MarkdownContent';

export default function Home() {
  return (
    <main className="home">
      <img className="headshot" src="/images/headshot.png" alt="headshot" />
      <MarkdownContent className="bio" path="/content/bio.md" />
    </main>
  );
}
