import React from 'react';
import 'App.scss';

import Prism from 'prismjs';

import { Outlet, Routes, Route } from 'react-router-dom';

import Home from 'components/Home';
import { Fragment, Fragments } from 'components/Fragments';
import Nav from 'components/Nav';
import NotFound from 'components/NotFound';

Prism.manual = true;

export default function App() {
  return (
    <>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="fragments" element={<Outlet />}>
          <Route index element={<Fragments />} />
          <Route path=":slug" element={<Fragment />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}
