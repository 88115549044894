import React from 'react';
import './Nav.scss';

import { Link } from 'react-router-dom';

export default function Nav() {
  return (
    <nav className="nav">
      <div className="left-links">
        <a className="link" href="/fragments">Fragments</a>
        <a className="link" href="/projects">Projects</a>
      </div>
      <Link to="/" className="logo-wrapper">
        <img className="logo-m" src="/images/logo/M.svg" alt="logo M" />
        <span className="letters">oo</span>
        <img className="logo-k" src="/images/logo/K.svg" alt="logo K" />
        <span className="letters">ie</span>
      </Link>
      <div className="right-links">
        <a className="link" href="https://github.com/mkel23">GitHub</a>
        <a className="link" href="https://www.linkedin.com/in/mkel23">LinkedIn</a>
      </div>
    </nav>
  );
}
