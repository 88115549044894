import React from 'react';

import './NotFound.scss';

export default function NotFound() {
  return (
    <main className="not-found">
      <h1>404</h1>
      <p>Whoops! Looks like we can't find that page!</p>
    </main>
  );
}
