import React from 'react';

import './Fragments.scss';

import { Link } from 'react-router-dom';

import content from 'content-manifest';

import { formatDate } from 'helpers';

export default function Fragments() {
  return (
    <main className="fragments">
      <h1>Fragments</h1>
      {content["fragments"].map((fragment, index) => {
        return (
          <div key={index} className="fragment">
            <Link to={`/fragments/${fragment.slug}`}>{fragment.title}</Link>
            <small className="date">{formatDate(fragment.date)}</small>
          </div>
        );
      })}
    </main>
  );
}
