import React, { useEffect, useState } from 'react';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkGemoji from 'remark-gemoji';
import Prism from 'prismjs';

export default function MarkdownContent({ className, highlight, path }) {
  const [markdown, setMarkdown] = useState();

  useEffect(() => {
    fetch(path)
      .then((response) => {
        return response.text()
      })
      .then((data) => {
        const parsedData = data.split('---');

        if (parsedData.length === 3) {
          const content = parsedData[2];
          setMarkdown(content);
        } else {
          setMarkdown(data);
        }
      });
  }, [path]);

  useEffect(() => {
    if (highlight) {
      Prism.highlightAll();
    }
  }, [highlight, markdown]);

  return (
    <ReactMarkdown className={className} children={markdown} remarkPlugins={[remarkGfm, remarkGemoji]} />
  );
}
