import React, { useEffect, useState } from 'react';

import './Loading.scss';

const DOT = '.';

export default function Loading() {
  const [dots, setDots] = useState('.');

  useEffect(() => {
    const timer = setTimeout(() => {
      setDots(DOT.repeat((dots.length % 3) + 1));
    }, 700);

    return () => clearTimeout(timer);
  }, [dots]);

  return (
    <main className="loading">
      <h1>{dots}</h1>
    </main>
  );
}
