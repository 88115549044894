import React, { useEffect, useState } from 'react';

import './Fragment.scss';

import { useParams } from 'react-router-dom';

import Loading from 'components/Loading';
import MarkdownContent from 'components/MarkdownContent';
import NotFound from 'components/NotFound';

import content from 'content-manifest';

import { formatDate } from 'helpers';

const ERROR = 'ERROR';

const findFragment = (content, slug) => {
  return content.fragments.find(fragment => fragment.slug === slug);
};

export default function Fragment() {
  const [fragment, setFragment] = useState();

  const { slug } = useParams();

  useEffect(() => {
    if (!fragment) {
      const foundFragment = findFragment(content, slug);
      if (foundFragment) {
        setFragment(foundFragment);
      } else {
        setFragment(ERROR);
      }
    }
  }, [fragment, slug]);

  if (!fragment) {
    return <Loading />;
  }

  if (fragment === ERROR) {
    return <NotFound />;
  }

  console.log(fragment);

  return (
    <main className="fragment line-numbers">
      <div className="content">
        <h1>{fragment.title}</h1>
        <small className="date">{formatDate(fragment.date)}</small>
        <MarkdownContent highlight path={fragment.file} />
      </div>
    </main>
  );
}
